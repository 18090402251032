import { tryLoadAndStartRecorder } from '@alwaysmeticulous/recorder-loader';
import { getEnvVar } from './env';

export async function setUpMeticulous() {
  // Only start the recorder if the METICULOUS_RECORDING_TOKEN is set
  if (getEnvVar('METICULOUS_RECORDING_TOKEN')) {
    await tryLoadAndStartRecorder({
      projectId: getEnvVar('METICULOUS_RECORDING_TOKEN'),
      isProduction: false,
    });
  }
}
