import { setUpMeticulous } from './setUpMeticulous';

export async function setUpWebpackEntry() {
  // Wait for Meticulous to finish setting up before loading the rest of the app
  // to ensure we capture all network requests.
  await setUpMeticulous();

  await import('./index');
}

setUpWebpackEntry();
